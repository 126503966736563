<template>
  <div>
     <iHeader
      backType="blank"
      :headStyle="['msd', 'dsm'].includes($cookies.get('tenant'))?{
          background: '#fff',
          color: '#13161B',
        }: {
          background: 'var(--themeColor)',
          color: '#fff',
        }"
      :backEvent="true"
      @back-event="projectGoBack()"
      :back="true"
    >
    <div slot="headText">
        <span class="head-txt">
          {{['pfizer'].includes($cookies.get('tenant'))?"租车进程":"地方车队进程"}}
        </span>
    </div>
    </iHeader>
    <!-- <div class="publicHead">
      <p>{{['pfizer'].includes($cookies.get('tenant'))?"租车进程":"地方车队进程"}}</p>
    </div> -->
    <div class="m-fleetProcess">
      <div style="margin-bottom: 1rem">
        <div class="fleetInfo" v-for="(items, index) in orderList" :key="index">
          <div class="processHeader" @click="items.isShow = !items.isShow">
            <div class="retract">
              <i class="iconarrowTop-fill" v-show="items.isShow"></i>
              <i class="iconarrowBottom" v-show="!items.isShow"></i>
            </div>
            <div class="headerInfo">
              <div class="headTitle">
                <p>{{items.title}}</p>
                <span>{{items.orderNum}}</span>
                <span class="headInfo">叫车人: {{items.dspName}}</span>
              </div>
            </div>
            <div>
            <svg class="icon" aria-hidden="true" v-if="items.list.length!=0">
              <use xlink:href="#iconright"/></svg>
            </div>
            <div class="clear"></div>
          </div>
          <div class="processBody" :class="items.isShow ? 'showBody' : 'hiddenBody'">
            <div
              class="bodyItem"
              :class="[item.createdOn ? 'complete' : '',item.dspName ? 'borderNone' : '']"
              v-for="(item, iindex) in items.list"
              :key="iindex"
            >
              <template v-if="item.taskStatus != -1">
                <div class="col-3">
                  <p
                    class="time"
                    v-if="item.createdOn"
                  >{{item.createdOn | formatDate('MM-dd hh:mm')}}</p>
                  <p v-else class="time" style="color:#999">未生成</p>
                </div>
                <div class="col-7">
                  <p
                    class="title"
                    style="padding: 0px !important;margin-left: 20px;margin-bottom: 5px"
                  >
                    <span class="icon-radius"></span>
                    <span>{{item.nodeName}}</span>
                  </p>
                  <div class="borderLeft">
                    <p>
                      <span>{{item.dspName}}</span>
                    </p>
                    <p>
                      <span>完成时间：</span>
                      <span>{{item.doneOn&&item.doneOn.replace("T"," ")}}</span>
                    </p>
                    <p v-if="item.isShowDesc">
                      <span>备注：</span>
                      <span>{{item.descr}}</span>
                    </p>
                  </div>
                </div>
                <div class="clear"></div>
              </template>
            </div>
            <div class="clear"></div>
          </div>
          <div class="clear"></div>
          <div class="devide_line"></div>
        </div>
      
      </div>
      <NoData v-if="!orderList.length"></NoData>
    </div>
  </div>
</template>

<script>
import { copyFileSync } from 'fs';

export default {
  data() {
    return {
      orderList: [],
    };
  },
  components: {},
  async created() {
    if (this.SmartStorage.get("routeListeners")) {
      this.setRouteListeners("projectGoBack");
      this.$root.$eventHub.$on("projectGoBack", (data) => {
        this.projectGoBack();
        this.$root.$eventHub.$off("projectGoBack"); //解绑当前监听，否则下轮监听还会持续叠加
      });
    } else {
      this.redirectRouteListeners(); //记录全局路由轨迹
    this.$root.$eventHub.$on("projectGoBack", (data) => {
      this.projectGoBack();
    });
    }
    
    this.initFromPage();
  },
  methods: {
    redirectRouteListeners() {
      this.SmartStorage.set("routeListeners", ["projectGoBack"]);
    },
    // 退出城市车队页面
    projectGoBack() {
      if (this.SmartStorage.get("routeListeners")[0] != "projectGoBack") {
        this.$router.back();
        this.removeRouteListeners("itemsGoBack");
      } else {
        const container = this.$cookies.get("container");
        switch (container) {
            case "browser":
                this._browserBack()
                break;
            case "h5":
                this._h5Back()
                break;
            case "native":
            default:
              this.closeAppWithReload();
      }
      }
    },
    initFromPage() {
      this.loadData();
    },
    async loadData() {
      let params = {
        ProposalId: this.SmartStorage.get("proposalId"), // '0abeda7e-d97b-40fc-a9b8-8b0c55c5bcc7'
        TypeDictVal: "V2",
      };
      let paramsLog = {
        ProposalId: this.SmartStorage.get("proposalId"), // 0abeda7e-d97b-40fc-a9b8-8b0c55c5bcc7
        FlowCodes: ["R-Car-Offline"],
      };
      let res1 = await this.services.QueryOrders(params);
      let resLog = await this.services.AppointTaskLogs(paramsLog);
      this.orderList = this.getData(res1.content, resLog.content);
    },
    getData(val1, val2) {
      let tenantList = ["msd", "dsm"];
      let tenant_code = this.$cookies.get("tenant");
      let isShowDesc = tenantList.includes(tenant_code)>-1;
     
      let dataListCopy = [];
      val1.forEach((item) => {
        let list = [];
        val2.forEach((items) => {
          if (item.supplierId === items.id) {
            if (isShowDesc) {
              items.extData = JSON.parse(items.extData);
              if (items?.extData?.hiddenTenants) {
                items.extData.hiddenTenants.includes(tenant_code) > -1
                  ? (items.taskStatus = -1)
                  : (items.taskStatus = 0);
              } else {
                items.taskStatus = 0;
              }
              if (items.nodeName.indexOf("报价") > -1) {
                items.isShowDesc = true;
                if (items.extData&&Object.keys(items.extData).length !== 0) {
                  items.descr =
                    (items.extData &&
                      items.extData[tenant_code] &&
                      items.extData[tenant_code].descr) ||
                    "";
                }
              }
              list.push(items);
            } else {
              list.push(items);
            }
          }
        });
        let listCopy = {};
        listCopy.list = list;
        listCopy.title = item.name;
        listCopy.dspName = item.dspName;
        listCopy.orderNum = item.orderNum;
        listCopy.isShow = true;
        dataListCopy.push(listCopy);
      });
      if(this.$route.query.orderNum){
        dataListCopy.filter((res,index) => {
          console.log(res);
          if(res.orderNum==this.$route.query.orderNum){
            dataListCopy=[];
            dataListCopy.push(res);
          }
        });
      }
      console.log(dataListCopy);
      return dataListCopy;
    },
  },
};
</script>

<style lang="sass" scoped>
@import '@/assets/projectProcess.scss';
</style>